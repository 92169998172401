<template>
  <div class="d-flex align-items-center">
    <compare-select-dropdowns
      :group="group"
      :selectedA.sync="selectedA"
      :selectedB.sync="selectedB"
    ></compare-select-dropdowns>
    <div class="col-4">
      <router-link
        class="btn btn-primary"
        :to="{
          name: 'compare-sets',
          params: {
            setIdA: selectedA,
            setIdB: selectedB,
            languageCode: languageCode,
          },
        }"
        >Compare</router-link
      >
    </div>
  </div>
</template>

<script>
import CompareSelectDropdowns from "../components/CompareSelectDropdowns.vue";
import * as UserSettings from "../user-settings";
import * as Utility from "../utilities";

export default {
  components: { CompareSelectDropdowns },
  props: ["group"],
  created() {
    this.selectedA = this.group.sets[1].setId;
    this.selectedB = this.group.sets[0].setId;
  },
  computed: {
    languageCode() {
      const userCc = UserSettings.getCountryCode();

      // Determine A's languageCode
      let setA = this.group.sets.find((x) => x.setId == this.selectedA);
      let languageA = Utility.getLanguageCodeByCountryCode(
        setA.languageCodes,
        userCc,
        setA.fallbackLanguageCode
      );

      // Determine B's languageCode
      let setB = this.group.sets.find((x) => x.setId == this.selectedB);
      let languageB = Utility.getLanguageCodeByCountryCode(
        setB.languageCodes,
        userCc,
        setB.fallbackLanguageCode
      );

      if (languageA === languageB) return languageA;

      // The two sets did not have a matching languageCode
      if (setA.fallbackLanguageCode === setB.fallbackLanguageCode)
        return setA.fallbackLanguageCode;

      // The two sets fallback was not the same
      const commonLanguageCodes = setA.languageCodes.filter((value) =>
        setB.languageCodes.includes(value)
      );
      if (commonLanguageCodes.length > 0) return commonLanguageCodes[0];

      // No matching, at all
      console.log(
        "ERROR: no matching languageCode between " +
          this.selectedA +
          " and " +
          this.selectedB
      );
      return "en-US";
    },
    setsB() {
      return this.group.sets.filter((x) => x.setId != this.selectedA);
    },
  },
  data() {
    return {
      selectedA: "",
      selectedB: "",
    };
  },
};
</script>
