var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center"},[_c('compare-select-dropdowns',{attrs:{"group":_vm.group,"selectedA":_vm.selectedA,"selectedB":_vm.selectedB},on:{"update:selectedA":function($event){_vm.selectedA=$event},"update:selected-a":function($event){_vm.selectedA=$event},"update:selectedB":function($event){_vm.selectedB=$event},"update:selected-b":function($event){_vm.selectedB=$event}}}),_c('div',{staticClass:"col-4"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
        name: 'compare-sets',
        params: {
          setIdA: _vm.selectedA,
          setIdB: _vm.selectedB,
          languageCode: _vm.languageCode,
        },
      }}},[_vm._v("Compare")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }