<template>
  <v-fragment>
    <div class="col">
      <b-form-select v-model="selectedSetA">
        <b-form-select-option
          v-for="set in group.sets"
          :key="set.setId"
          :value="set.setId"
          >{{ set.displayName }}</b-form-select-option
        >
      </b-form-select>
    </div>
    with
    <div class="col">
      <b-form-select v-model="selectedSetB">
        <b-form-select-option
          v-for="set in setsB"
          :key="set.setId"
          :value="set.setId"
          >{{ set.displayName }}</b-form-select-option
        >
      </b-form-select>
    </div>
  </v-fragment>
</template>

<script>
export default {
  props: ["group", "selectedA", "selectedB"],
  watch: {
    selectedA() {
      this.selectedSetA = this.selectedA;
    },
    selectedB() {
      this.selectedSetB = this.selectedB;
    },
    selectedSetA() {
      // Ensure selectedSetB remains set
      if (this.selectedSetB === this.selectedSetA) {
        let sets = this.setsB;
        this.selectedSetB = sets[0].setId;
      }

      this.$emit("update:selectedA", this.selectedSetA);
    },
    selectedSetB() {
      this.$emit("update:selectedB", this.selectedSetB);
    },
  },
  computed: {
    setsB() {
      return this.group.sets.filter((x) => x.setId != this.selectedSetA);
    },
  },
  data() {
    return {
      selectedSetA: this.selectedA,
      selectedSetB: this.selectedB,
    };
  },
};
</script>
