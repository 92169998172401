<template>
  <div class="col-lg-6">
    <div v-for="group in model.groups" :key="group.id">
      <h4>{{ group.displayName }}</h4>
      <compare-select-row :group="group"></compare-select-row>
    </div>
  </div>
</template>

<script>
import CompareSelectRow from "../components/CompareSelectRow.vue";

export default {
  components: {
    CompareSelectRow,
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$root.$api.getCompareIndex().then((data) => {
        this.model = data;
      });
    },
  },
  data() {
    return {
      model: {
        groups: [],
      },
    };
  },
};
</script>
